.form-create{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 63%;
}

.container-input-first{
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}



.chrome-picker>div>div>div>div {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
};