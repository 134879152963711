/* Modification du style du bouton import file */
.import .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
.import .btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: bold;
    text-align:center;
    overflow: hidden;
    word-wrap: break-word; 
    width: 265px;
  }

.import .upload-btn-wrapper Input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    
  }

/* Style du modal import Excel */
.styleModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background-color: white;
    border: 3px solid #3b3b3b;
    box-shadow: 20px;
    border-radius: 6px;
    padding: 40px 40px;
  };

.alertSnakbar {
  position: fixed;
    top: -90%;
    left:40%;
}
