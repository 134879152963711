.spinner {
  height: auto;
  animation: spinner 4s linear infinite;
}

@keyframes spinner {
  from { transform: rotate(0deg);}
  to { transform: rotate(360deg);}
}

.hidden {
  display: none;
}

.active {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 100%;
  z-index: 10000;
  overflow: hidden;
}