

.border-title {
  padding-bottom: 10px;
  border-bottom: 2px solid #4d4d4d;
}

.button-inline-list {
  margin-top: 25px;
  display: inline-flex;

  Button {
      margin-right: 25px;
  }
}

.subtitle {
  font-weight: bold;
  font-size: 20px;
}

.paddingTop {
  padding-top: 25px;
}

.infoSupp {
  width: 65%;
  text-justify: inter-word;
  text-align: justify;
}

.generic-message {
  font-style: italic;
}