.form-create{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 65%;
}

.input-label{
  flex-direction: row;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
}

.logo-area {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.thanks-photo-area {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.event-Name{
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.event-logo{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.event-promoter{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.event-date{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 360px;
}

.date-inputs{
  margin-top : 10px;
  width: 125%;
  &:first-child {
    margin-right: 15px;
  }
}

.event-adress{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.event-descFr{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.event-descEng{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.event-ThanksFr{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.event-ThanksEng{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.event-ThanksImage{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.event-otherInfo{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.event-numberParticipant{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.event-checkBoxGES {
  display: inline-flex;
}

.checkbox-label{
  margin-top: 50px;
  font-weight: bold;
  font-size: 16px;
}

.warningPicture {
  font-size: small;
  font-style: italic;
  width: 70%;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.address-warning {
  margin-top: 25px;
}

.not-enough-tokens {
  color: red;
}
